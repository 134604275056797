import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import Dashboard from "components/Courses/CourseAdmin/Dashboard.js";

import { auth } from "api/auth";
import { fetchUserDataByUid } from "api/db";

const InstructorDashboard = () => {
  const [userData, setUserData] = useState();

  useEffect(() => {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    setUserData(userData);

    if (!userData && auth && auth.currentUser && auth.currentUser.uid) {
      fetchUserDataByUid(auth.currentUser.uid).once("value", function(
        snapshot
      ) {
        setUserData(snapshot.val());
      });
    }
  }, [auth && auth.currentuser]);

  useEffect(() => {
    if (
      userData &&
      !(
        (userData.instructor &&
          userData.instructor.find(s => s.includes("f"))) ||
        (userData.tags && userData.tags.includes("team"))
      )
    ) {
      navigate("/login");
    }
  }, [userData]);

  return (
    <div>
      <Dashboard userData={userData} cohort={"figma"} />
    </div>
  );
};

export default InstructorDashboard;
